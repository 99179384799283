.slc-electricityConsumption {
    position: relative;
    width: 100%;
    /*
    display: flex;
    align-items: center;
    justify-content: space-around;
    */
}

.slc-electricityConsumption-question-people {
    margin-bottom: 15px;
}

.slc-electricityConsumption-options {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.slc-electricityConsumption-option {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slc-electricityConsumption-option:hover {
    cursor: pointer;
}

.slc-electricityConsumption-option-image {
    width: 80%;
}

.slc-electricityConsumption-option-label {
    margin-top: 15px;
}

/*
This widgets must not be visibile in this configurator-lite version
*/
.slc-electricityConsumption-questions {
    display: none;
    margin-top: 20px;
}