.ikea-wolmann-info {
    width: 100%;
    max-width: 800px;
    padding: 0 20px 20px 20px;
}

.ikea-wolmann-info__logos {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(.6);
}

.ikea-wolmann-info__text {
    text-align: justify;
}