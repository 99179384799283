.progress {
    margin: 0;
    height: 2px;
    width: 100%;
    background-color: #EEB102;
    border-radius: 2px;
}

.progress .indeterminate {
    background-color: #FFC402;
}