.slc-roofOrientation {
    position: relative;
    width: 100%;
    height: 300px;
}

.slc-roofOrientation-image {
    background: #333;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.slc-roofOrientation-chooser {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 300px;
}

.slc-roofOrientation-chooser-image-container {
    width: 100%;
    height: 100%;
}

.slc-roofOrientation-chooser-image {
    width: 100%;
    height: 100%;
}

.slc-cardinal-point {
    position: absolute;
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: white;
    background-color: #cecece;
    border-radius: 20px;
}

.slc-cardinal-point-selected {
    background-color: #f7bd01;
}

.slc-cardinal-point:hover {
    cursor: pointer;
}

.slc-cardinal-point-N {
    top: 1%;
    left: 50%;
    transform: translateX(-50%);
}

.slc-cardinal-point-NE {
    top: 14%;
    left: 76%;
}

.slc-cardinal-point-E {
    top: 50%;
    right: 1%;
    transform: translateY(-50%);
}

.slc-cardinal-point-SE {
    top: 76%;
    left: 76%;
}

.slc-cardinal-point-S {
    bottom: 1%;
    left: 50%;
    transform: translateX(-50%);
}

.slc-cardinal-point-SW {
    top: 76%;
    left: 14%;
}

.slc-cardinal-point-W {
    top: 50%;
    left: 1%;
    transform: translateY(-50%);
}

.slc-cardinal-point-NW {
    top: 13%;
    left: 13%;
}