.slc-quotation-impossible-message {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: absolute;
    width: 100%;
    text-align: left;
    background: white;
    padding: 5px;
    z-index: 1;
    height: auto;
    box-shadow: 1px 1px 2px black;
}

.slc-quotation-impossible-message-icon {
    color: red;
}

.slc-quotation-impossible-message-text {
    width: 80%;
}