.slc-table-solare-conviene {
    font-family: 'Novecentosanswide-Medium';
    border-collapse: separate !important;
    border-spacing: 0 !important;
}

.slc-table-solare-conviene tr {
    border: none;
}

.slc-table-solare-conviene td {
    padding: 9px 2px;
    line-height: 1;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
}

.slc-table-solare-conviene tr:nth-child(even) {
    background-color: #ffffcd;
}

.slc-table-solare-conviene tr:last-child {
    background-color: #f7bd01;
}

.slc-table-solare-conviene td:nth-of-type(2),
.slc-table-solare-conviene td:nth-of-type(3),
.slc-table-solare-conviene td:nth-of-type(4) {
    text-align: center;
}


@media screen and (min-width: 993px) {
    table.slc-table-solare-conviene {
        font-size: .9em;
    }
}