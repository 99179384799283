.slc-wizard-infos-btn {
    position: absolute;
    top: -25px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: #f7bd01;
    border-radius: 50%;
}

.slc-wizard-infos-btn-open {
    right: 5px;
}

.slc-wizard-infos-btn-close {
    display: none;
    right: 5px;
}

.slc-wizard-infos-btn-open:hover,
.slc-wizard-infos-btn-close:hover {
    cursor: pointer;
    background: #ffc403;
}

.slc-wizard-infos-step {
    background: rgba(255,255,255,.9);
    height: 100vh;
    padding: 1.5rem;
    display: none;
}

.slc-page-wizard-infos--opened [class^="slc-wizard-infos-step"] {
    display: block;
}

.slc-wizard-infos-small {
    font-size: .8em;
}

@media screen and (min-width: 992px) {
    .slc-wizard-infos-btn {
        display: none;
    }
    
    .slc-wizard-infos-step {
        display: block;
        height: auto;
    }
}