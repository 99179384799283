.slc-configuration-state {
    position: relative;
}

.slc-configuration-state__title {
    margin-bottom: 10px;
    padding: 0 20px;
    height: 50px;
    line-height: 50px;
    text-align: left;
    font-size: 1.4em;
    font-weight: bold;
    color: #f7bd01;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
    /*
    color: black;
    background-color: #f7bd01;
    */
}

.slc-configuration-state__item {
    position: relative;
    margin: 0 0 10px 30px;
    transition: color .3s;
}

.slc-configuration-state__item__label {
    position: relative;
    font-weight: bold;
}

.slc-configuration-state__item__label::before {
    content: "•";
    position: absolute;
    left: -10px;
    color: #f7bd01;
}

.slc-configuration-state__map-image {
    max-width: 30%;
    padding-left: 20px;
    margin-bottom: 4px;
}

.slc-configuration-state__map-image img {
    width: 100%;
}

.slc-editing-data {
    color: #f7bd01;
}

.slc-editing-data::before {
    opacity: 1
}

@media screen and (min-width: 992px) {
    .slc-configuration-state__title {
        display: none;
    }

    .slc-configuration-state__item {
        margin-left: 10px;
    }

    .slc-configuration-state__map-image {
        max-width: 50%;
        padding-left: 0;
    }
}