.slc-modal {
    position: fixed;
    margin: 0 !important;
    width: 100% !important;
    max-height: 100% !important;
    top: 0;
    bottom: 0;
    background-color: white !important;
    border-radius: var(--slc-border-radius) !important;
    z-index: 3;
}

.slc-modal-header {
    padding: 24px 24px 0;
    text-align: right;
}

.slc-modal-close {
    display: inline-flex;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    background: #F7FBFF;
    border: 1px solid var(--slc-grey-light-color);
    cursor: pointer;
}

.slc-modal-close-icon {
    width: 14px;
    height: 14px;
    background: transparent;
}

.slc-modal-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.slc-modal .slc-form-submitBtn {
    width: 100%;
}

@media screen and (min-width: 992px) {
    .slc-modal {
        position: fixed !important;
        top: 50% !important;
        left: 50% !important;
        right: auto !important;
        bottom: auto !important;
        transform: translate(-50%, -50%);
        width: 55% !important;
        max-width: 640px;
    }
    /* .slc-modal {
        margin: auto !important;
        width: 55% !important;
        max-width: 640px;
        max-height: auto !important;
        top: auto;
        bottom: auto;
    } */
}