.slc-roofArea {
    position: relative;
}

.slc-roofArea-input-draw {
    background: #333;
    width: 100%;
    height: 300px;
}

.slc-roofArea-input-draw-clear-btn {
    display: none;
    position: absolute;
    top: 8px;
    left: 8px;
    padding: 0 5px;
    height: 28px;
    line-height: 28px;
    color: white;
    background-color: #f7bd01;
    border-radius: 2px;
    z-index: 1;    
}

.slc-roofArea-input-draw-clear-btn:hover {
    cursor: pointer;
}

.slc-roofArea-input-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.slc-roofArea-input-text__label {
    margin-bottom: 10px;
    font-weight: bold;
}

/*
.slc-roofArea-input-text__input {
    margin: 0 !important;
    width: 150px !important;
}
*/

.slc-step-1 .slc-form-input-text {
    padding: 5px !important;
    height: 25px !important;
}

/**                                                                             Google Maps Custom
==================================================================================================
*/

a[href^="https://maps.google.com"] {
    display: none !important;
}

/*
.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
}
*/

.gm-style-cc {
    display: none;
}

/**                                                                       Google Maps Custom - end
==================================================================================================
*/

@media screen and (min-width: 420px) {

    .slc-step-1 .slc-form-input-text {
        padding: 15px !important;
        height: initial !important;
    }

    .slc-roofArea-input-text {
        flex-direction: row;
    }

    .slc-roofArea-input-text__label {
        margin: 0 10px 0 0;
        font-weight: normal;
    }

}