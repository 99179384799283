.slc-wizard-infos-step-1__image {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 320px;
    height: 180px !important;
    margin-top: 20px;
}

@media screen and (min-width: 992px) {

    .slc-wizard-infos-step-1__image {
        margin-top: 20px;
        width: 100%;
        height: auto !important;
    }

}