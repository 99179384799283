.slc-page-home .slc-page-header {
    margin-bottom: 0 !important;
    width: 100%;
    border-bottom: 1px solid #bbb;
}

.slc-page-home .slc-header-logo__image:hover {
    cursor: initial;
}

.slc-page-body__content__infos {
    text-align: justify;
}