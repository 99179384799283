.slc-page-quotations-main {
    padding: 0 1.5rem !important;
}

.slc-page-quotations-form {
    margin-top: 20px;
    padding: 0 1.5rem !important;
    display: flex;
    flex-direction: column;
    gap: 40px;
    /*padding: 0 3rem 0 1.5rem  !important;*/
}

@media screen and (min-width: 992px) {
    .slc-page-quotations-main {
        padding: 0 1.5rem 0 3rem !important;
        border-right: 1px solid #bbb;
    }

    .slc-page-quotations-form {
        margin-top: 0;
        padding: 0 3rem 0 1.5rem !important;
    }
}