.slc-loading {
    position: fixed;
    top: 0;
    left: 0;
    width: 101%;
    height: 101%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 1);
    z-index: 10;
}

.slc-loading--absolute {
	position: absolute;
}

.slc-loading-body {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.lds-dual-ring {
	display: flex;
	justify-content: center;
	width: 64px;
	height: 64px;
}
/* orig
.lds-dual-ring {
	display: inline-block;
	width: 64px;
	height: 64px;
}
*/
.lds-dual-ring:after {
	content: " ";
	display: block;
	width: 46px;
	height: 46px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid #f7bd01;
	border-color: #f7bd01 transparent #f7bd01 transparent;
	animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}