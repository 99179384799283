.slc-header {
    width: 100%;
}

.slc-header-logo {
    padding: 0 10px 10px;
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.slc-header-logo__image {
    width: 90px;
}

.slc-header-logo__image:hover {
    cursor: pointer;
}

.slc-header-logo__ikea {
    width: 180px;
}

.slc-header-title {
    padding: 10px 20px;
    text-align: left;
    font-size: 1.4em;
    font-weight: bold;
    color: #f7bd01;
    border-top: 1px solid #bbb;
    border-bottom: 1px solid #bbb;
}

@media screen and (min-width: 400px) {

    .slc-header-logo {
        padding: 0 20px 15px;
    }

    /*
    .slc-page-home .slc-header-logo {
        padding: 0 0 15px;
    }
    */

    .slc-header-logo__image {
        width: 116px;
    }

    .slc-header-logo__ikea {
        width: 240px;
    }
    
}

@media screen and (min-width: 600px) {

    .slc-header-logo__image {
        width: 150px;
    }

    .slc-header-logo__ikea {
        width: 300px;
    }
    
}

@media screen and (min-width: 993px) {

    .slc-header-logo {
        padding: 0 40px 15px;
        height: 80px;
    }

    .slc-header-logo__image {
        width: auto;
    }
    
    .slc-header-logo__ikea {
        width: auto;
    }

    /*
    .slc-page-home .slc-header-logo {
        padding: 0 0 15px;
        height: 80px;
        max-width: 1170px;
        margin: 0 auto;
    }
    */

    /*
    .slc-header-logo__image {
        width: 180px;
    }
    
    .slc-header-logo__ikea {
        width: 275px;
    }
    */

    .slc-header-title {
        padding: 0;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 1.5em;
    }

}