.slc-roofInclination {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.slc-roofInclination-option {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.slc-roofInclination-option:hover {
    cursor: pointer;
}

.slc-roofInclination-option-image {
    width: 80%;
}

.slc-roofInclination-option-label {
    margin-top: 15px;
}