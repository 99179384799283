.slc-page-home .slc-page-body {
    position: relative;
    /*height: 100%;*/
    display: grid;
    grid-template-rows: auto auto auto 1fr;
    grid-template-columns: minmax(0, 1fr);
}

.slc-page-home .slc-page-footer {
    font-size: 1em;
}

.slc-page-home .slc-container {
    margin: 0 auto;
    padding: 0 10px;
}

.slc-page-home .slc-page-row {
    width: 100%;
    border-bottom: 1px solid #bbb;
}

.slc-page-home .slc-page-row:nth-of-type(3), .slc-page-row:nth-of-type(4) {
    border-bottom: none;
}

.slc-page-home .slc-infos {
    padding-top: 1.8em;
    padding-bottom: 1.8em;
    text-align: center;
}

.slc-page-home .slc-infos_title {
    font-size: 1.6em;
    color: #FFC401;
}

.slc-page-home .slc-infos_infos {
    font-size: 1.2em;
}

.slc-page-home .slc-steps {
    padding-top: 2em;
    padding-bottom: 3em;
    display: flex;
    justify-content: space-evenly;
    font-family: 'Novecentosanswide-Medium';
}

.slc-page-home .slc-steps_step {
    width: 33%;
    display: flex;
    flex-direction: column;
    text-align: center;
    border-right: 1px solid #eee;
}

.slc-steps_step:last-child {
    border-right: none;
}

.slc-page-home .slc-steps_step_number {
    margin-top: -30px;
    font-size: 80px;
    line-height: 80px;
    color: #FFC401;
}

.slc-page-home .slc-steps_step_text {
    font-size: 11px;
    line-height: 18px;
    text-transform: uppercase;
}

.slc-page-home .slc-form {
    padding: 2em 4em 2em;
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: auto;
    justify-items: center;
    align-items: center;
    row-gap: 5px;
}

.slc-page-home .slc-link-battery {
    display: none;
    text-align: center;
    padding-bottom: 2em;
}

.slc-page-home .slc-link-battery .slc-text-link {
    font-weight: bold;
}

.slc-page-home #slc-btn-start {
    width: 100%;
    height: 3rem;
    font-family: "Novecentosanswide-Medium";
    border-radius: 10px;
}

.slc-page-home .slc-form-input-text {
    margin: 0 !important;
}

.slc-page-home .slc-image-container {
    padding: 0;
    line-height: 0;
}

.slc-page-home .slc-image {
    width: 100%;
    max-width: 100%;
}

.slc-page-home .slc-sconto-ecobonus-container {
    position: relative;
}

.slc-page-home .slc-image-sconto-ecobonus {
    position: absolute;
    width: 30%;
    top: -10px;
    left: 0;
}

@media screen and (min-width: 600px) {

    .slc-page-home .slc-infos_title {
        font-size: 2.5em;
    }
    
    .slc-page-home .slc-infos_infos {
        font-size: 1.5em;
    }

    .slc-page-home .slc-steps {
        padding-top: 1.5em;
        padding-bottom: 1.5em;
        justify-content: space-evenly;
    }
    
    .slc-page-home .slc-steps_step {
        width: auto;
        flex-direction: row;
        align-items: center;
        text-align: left;
        border-right: none;
    }
    
    .slc-page-home .slc-steps_step_number {
        font-size: 120px;
        line-height: 120px;
    }
    
    .slc-page-home .slc-steps_step_text {
        margin-left: .5em;
        font-size: 1.1rem;
        line-height: 24px;
    }

    .slc-page-home .slc-form {
        padding: 2em 10px 2em;
        grid-template-rows: auto;
        grid-template-columns: 2fr 1fr;
        column-gap: 10px;
    }

    .slc-page-home .slc-link-battery {
        text-align: left;
    }

    .slc-page-home #slc-btn-start {
        font-size: 1.4em;
    }

}

@media screen and (min-width: 992px) {

    .slc-page-home .slc-container {
        max-width: 1170px;
    }

}

@media screen and (min-width: 1170px) {
    .slc-page-home .slc-container {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (min-width: 1220px) {
    .slc-page-home .slc-image-sconto-ecobonus {
        left: calc(-100vw/50);
    }
}

@media screen and (min-width: 1300px) {
    .slc-page-home .slc-image-sconto-ecobonus {
        left: calc(-100vw/20);
        top: 10px;
    }
}

/**
    HD Resoluttions
    @media screen and (min-width: 2000px) {}
*/