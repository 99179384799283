.slc-page.slc-page-wizard {
    grid-template-rows: auto 5fr 1fr;
}

.slc-page-wizard-infos {
    position: absolute;
    left: 0;
    height: 20px;/*100vh;*/
    padding: 0 !important;
    z-index: 10;
    transition: left .2s;
}

.slc-page-wizard-wizard {
    margin-top: 20px;
    padding: 0 1.5rem !important;
}

.slc-page-wizard-configuration-state {
    margin-top: 20px;
    padding: 0 !important;
}

@media screen and (min-width: 992px) {

    .slc-page-wizard-infos {
        position: relative;
        left: 0 !important;
        padding: 0 1.5rem 0 3rem !important;
        background: none;
        transition: none;
        z-index: 1;
    }

    .slc-page-wizard-wizard {
        margin-top: 0;
        border-left: 1px solid #bbb;
        border-right: 1px solid #bbb;
    }

    .slc-page-wizard-configuration-state {
        margin-top: 0;
        padding: 0 3rem 0 1.5rem  !important;
    }

}