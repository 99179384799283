.slc-quotations-form {
    position: relative;
}

.slc-font-title.slc-quotations-form__title {
    margin-bottom: 20px;
    font-family: 'Novecentosanswide-Medium';
    font-size: 1.1em;
    font-weight: bold;
}

.slc-quotations-form .slc-quotations-form__title {
    border-bottom: 1px solid #bbb;
}

.slc-quotations-form .slc-form-input-text-widget {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 15px;
}

.slc-quotations-form .slc-form-input-text-widget__label {
    margin-bottom: 3px;
}

.slc-quotations-form .slc-form-input-text-widget__input-container {
    width: 100% !important;
}

.slc-quotations-form .slc-form-input-text-widget__input {
    text-align: left;
    padding: 5px !important;
}

.slc-quotations-form .slc-form-input-text-widget__input:focus {
    background-color: #F7BD00 !important;
}

.slc-quotation-form_buttons {
    margin-top: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slc-quotations-form #submit {
    height: inherit;
    height: inherit;
    line-height: inherit;
    padding: 8px 16px;
}

.slc-quotations-form__chiamaci {
    margin-top: 30px;
    padding: 20px;
    background: #f7bd01;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slc-quotations-form__chiamaci-img {
    float: left;
}

.slc-quotations-form__chiamaci-txt {
    width: 80%;
    text-align: center;
    font-size: 1.5em;
    line-height: 1.1;
}

.slc-quotations-form__chiamaci-phone {
    margin-top: 10px;
    font-size: 1.2em;
}

.consensoReadPrivacy {
    margin-bottom: 10px;
}

.slc-quotations-form .slc-checkbox {
    margin-right: 5px;
}

.slc-quotations-form__consensi__btn-close:hover {
    cursor: pointer
}

.slc-quotations-form__consenso__content {
    display: inline-block;
}

.slc-quotations-form__consensi {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    min-height: 100%;
    padding: 20px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    /*align-items: center;*/
    background: rgb(246,189,0);
    z-index: 10;
}

.slc-quotations-form__consensi__btn-close {
    width: 150px;
    left: 100%;
    transform: translateX(-150px);
}

.slc-quotations-form__consensi__btn-done {
    width: 150px;
    left: 50%;
    transform: translateX(-50%);
}

.slc-quotations-form__consensi__btn-close,
.slc-quotations-form__consensi__btn-done
{
    position: relative;
    box-shadow: none !important;
    background: white !important;
    color: #f7bd01 !important;
}

.slc-quotations-form__consensi [class^="consenso"] {
    position: relative;
    margin-bottom: 15px;
    transition: padding 1s;
}

.slc-quotations-form__consensi__consenso_desc,
.slc-quotations-form__consensi__consenso_input {
    transition: opacity .2s;
}

.slc-form-item-validation-error .slc-quotations-form__consensi__consenso_desc,
.slc-form-item-validation-error .slc-quotations-form__consensi__consenso_input {
    opacity: .2;
}