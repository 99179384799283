.slc-coupon-form__info {
    margin-bottom: 15px;
}

.slc-coupon-form__form {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.slc-coupon-form__form .slc-form-input-text-widget {
    margin-bottom: 0;
}
.slc-coupon-form__form .slc-form-input-text-widget__input-container {
    width: 100% !important;
}

.slc-coupon-form__form .slc-form-input-text-widget__input {
    text-align: left;
    padding: 5px !important;
}

.slc-coupon-form__form .slc-form-input-text-widget__input:focus  {
    background-color: #F7BD00 !important;
}

.slc-coupon-form__form .slc-button {
    height: 28px;
    padding: 0 10px;
    line-height: 28px;
}

.slc-coupon-form__form .slc-loading-bar-container {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.slc-coupon-form__form .progress {
    margin: 0;
    height: 2px;
    background-color: #F7BD01;
}

.slc-coupon-form__form .progress .indeterminate {
    background-color: #F8CE12;
}