/**
    At the moment, no responsive specific version.
    If a responsive specific version is implemented, we can use:
        Media queries:

            @media screen and (min-width: 993px)                            -> desktop
            @media screen and (min-width: 601px) and (max-width: 992px)     -> tablet
            @media screen and (max-width: 600px)                            -> mobile
        
        Parent context selector:

            .responsive-desktop                                             -> desktop
            .responsive-tablet                                              -> tablet
            .responsive-mobile                                              -> mobile
    
    Mobile version can be developed separately

        QuotationsMobile.js
        QuotationsMobiel.css

*/

/*@media screen and (min-width: 993px) {*/

.slc-page-quotations .slc-page-header {
    margin-bottom: 15px !important;
}

.slc-quotations {
    margin-bottom: 30px;
}

.slc-quotations-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*
.slc-quotations-row-images {
    margin-bottom: 20px;
}
*/

.slc-quotations-row-data {
    padding: 5px 0;
    min-height: 30px;
    border-top: 1px solid #bbb;
}

.slc-quotations-row-generic {
    padding: 5px 0;
    min-height: 30px;
}

.slc-quotations-row-generic.slc-quotations-ecobonus-text .slc-quotations-cell {
    margin-top: 10px;
    width: 100%;
    font-weight: bold;
}

.slc-quotations-row-generic.slc-quotations-schedaprodotti-link .slc-quotations-cell {
    width: 100%;
}

.slc-quotations-row-generic.slc-quotations-schedaprodotti-link a {
    font-weight: normal;
    color: inherit;
    text-decoration: underline;
}

.slc-quotations-cell {
    position: relative;
    width: 31%;
}

.slc-quotations-cell-data {
    padding: 0 2px;
}

.slc-quotations-cell-data:first-of-type {
    padding-left: 5px;
}

.slc-quotations-cell-data:last-of-type {
    padding-right: 5px;
}

.slc-quotations-image {
    width: 100%;
    max-height: 156px;
}

.slc-quotations-sconto-ecobonus {
    position: absolute;
    width: 35%;
    top: -8%;
    left: -8%;
}

.slc-quotations-sconto-ecobonus img {
    width: 100%;
}

.slc-quotation-best-choice {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 16%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .4em;
    color: white;
    background-color: #FDBA17;
}

.slc-quotation-best-choice img {
    display: none;
}

/*
.slc-quotation-best-choice {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
}

.slc-quotation-best-choice img {
    width: 100%;
}
*/

.slc-quotations-cell:nth-of-type(2),
.slc-quotations-cell:nth-of-type(3) {
    text-align: center;
}

.slc-data-value-content-info {
    display: none;
}

/* Labels info icon */
.slc-data-label-info {
    content: '?';
    display: inline-block;
    position: absolute;
    top: 0;
    right: -20px;
    width: 20px;
    height: 20px;
    font-weight: normal;
    line-height: 20px;
    color: white;
    background: #535353;
    border-radius: 10px;
    text-align: center;
    z-index: 2;
}

.slc-data-label-info:hover {
    cursor: pointer;
    background: #F7BD02;
}

/* Labels info content */
.slc-data-label-info-content {
    position: absolute;
    left: 120%;
    top: 0;
    min-width: 300px;
    padding: 10px;
    text-align: left;
    color: grey;
    background: white;
    border: 1px solid grey;
}

.slc-data-label-info-content.slc-position-vertical-middle {
    transform: translateY(-50%);
}

.slc-data-label-info-content__head {
    margin-bottom: 10px;
    font-weight: bold;
}

.slc-quotations-nav-button {
    height: 24px !important;
    line-height: 24px!important;
    padding-left: 3px!important;
    padding-right: 3px!important;
    width: 100% !important;
    max-width: 100px;
    font-weight: bold;
    border-radius: 8px;
}

.slc-quotations-nav-button:nth-of-type(2) {
    margin-top: 1px;
}

@media screen and (min-width: 600px) {

    .slc-quotation-best-choice {
        font-size: .8em;
    }
    
}

@media screen and (min-width: 768px) {

    .slc-quotations-nav-button {
        height: 36px !important;
        line-height: 36px !important;
        padding: 0 16px !important;
        width: initial !important;
        max-width: initial;
    }
    
    .slc-quotations-nav-button:nth-of-type(2) {
        margin-top: 0;
        margin-left: 20px;
    }
}

@media screen and (min-width: 1024px) {

    .slc-quotation-best-choice {
        font-size: 1.1em;
    }

    .slc-data-value-content {
        display: inline-block;
        position: relative;
    }
    
    .slc-data-value-content-info {
        display: none;
        position: absolute;
        top: 0;
        transform: translateX(-100%);
    }
    
}

@media screen and (min-width: 1600px) {

    .slc-quotation-best-choice {
        font-size: 1.4em;
    }
    
}

@media screen and (min-width: 1700px) {
    .slc-quotations-sconto-ecobonus {
        top: -10%;
        left: -6%;
    }
}

/**                                                                       Best Quotation Symbol
===============================================================================================
*/

.slc-quotation-best-symbol:hover {
    cursor: pointer;
}

.slc-quotation-best-symbol:after {
    position: absolute;
    bottom: 0;
    content: '*';
    color: #f7bd01;
    transition: transform .3s;
    transform: scale(1);
}

.slc-quotation-best-symbol-tooltip {
    padding: 0 5px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: .8em;
    /* color: #f7bd01; */
    background-color: white;
    border: 1px solid #f7bd01;
}

/**                                                                        HtmlPlusMinus Widget
===============================================================================================
*/

.slc-plus-minus {
    display: flex;
    align-items: center;
    justify-content: center;
}

.slc-plus-minus__button {
    width: 20px;
    height: 20px;
    color: white;
    background-color: #FEBA17;
    cursor: pointer;
    user-select: none;
    transition: opacity .3s ease-in;
}

.slc-plus-minus__button-disabled {
    opacity: .5;
    cursor: auto;
}

.slc-plus-minus__button-minus {
    margin-right: 5px;
}

.slc-plus-minus__button-plus {
    margin-left: 5px;
}

.slc-plus-minus__label {
    padding: 0 10px;
    min-width: 140px;
}

@media screen and (max-width: 600px) {
    .slc-data-capacitaSistemaAccumulo .slc-plus-minus__label {
        padding: 0;
        max-width: 50px;
    }
}

@media screen and (max-width: 768px) {

    .slc-plus-minus__button {
        width: 14px;
        height: 14px;
        line-height: 14px;
    }

    .slc-plus-minus__button-minus {
        margin-right: 0;
    }

    .slc-plus-minus__button-plus {
        margin-left: 0;
    }

    .slc-plus-minus__label {
        min-width: auto;
    }

}

/**                                                                              Specific items
===============================================================================================
*/

/*.slc-data-sistemaSolare .slc-data-label,
.slc-data-sistemaSolare .slc-data-value,
.slc-data-produzioneAnnualeGarantita .slc-data-value,
*/
.slc-data-totale .slc-data-label,
.slc-data-totale .slc-data-value
/*.slc-data-totaleScontato .slc-data-label,
.slc-data-totaleScontato .slc-data-value
.slc-data-scegli .slc-data-label*/ {
    font-weight: bold;
}

/* changed specification: this is not needed any more (we hide it with css) */
.slc-data-numeroPannelli .slc-data-value,
.slc-data-potenza .slc-data-value,
.slc-data-capacitaSistemaAccumulo .slc-data-value-plus,
.slc-data-accumulatoreProposto .slc-data-value {
    display: none;
}

.slc-quotations-row-data.slc-data-sistemaSolare,
.slc-quotations-row-data.slc-data-totale
/*.slc-quotations-row-data.slc-data-totaleScontato*/ {
    font-size: 1.2em;
}

.slc-data-sistemaSolare {
    align-items: baseline;
    border-top: none;
}

.slc-data-sistemaSolare .slc-data-value div:nth-of-type(2) {
    font-size: .8em;
}

.slc-quotations-row-data.slc-data-totale {
    border-top: 1px solid #ffb500;
    border-bottom: 1px solid #ffb500;
}

.slc-data-totale .slc-data-value {
    /*font-size: 1.2em;*/
    transition: color .5s;
    /*text-decoration: line-through;*/
}

.slc-quotations-row-data.slc-data-instalments {
    border-top: none;
    border-bottom: 1px solid #ffb500;
}

.slc-quotations-row-data.slc-data-importoDetrazione {
    border-top: none;
    border-bottom: 1px solid #ffb500;
}

.slc-quotations-row-data.slc-data-billAdvantage {
    border-top: none;
    border-bottom: 1px solid #ffb500;
}

.slc-data-totaleScontato .slc-data-value-selected {
    color: black;
}

.slc-quotations-row-data.slc-data-scegli {
    padding-top: 15px;
    border-top: none;
}

/*
.slc-button-quotation {
    min-width: 65%;
    max-width: 65%;
    height: auto !important;
    line-height: 16px !important;
    padding: 8px 10px !important;
    text-transform: initial !important;
}
*/

.slc-button-quotation {
    min-width: 90%;
    max-width: 90%;
    height: auto !important;
    line-height: 16px !important;
    padding: 8px 6px !important;
    font-size: 12px !important;
    text-transform: uppercase !important;
    font-weight: bold;
    border-radius: 8px !important;
}

.slc-button-quotation:hover {
    background-color: #0058A3 !important;
}

.slc-data-value-button--scegli-plus {
    margin-right: -3px;
}

.slc-button-update-config {
    /*text-transform: uppercase !important;*/
    font-size: 12px !important;
    text-transform: initial !important;
    line-height: 12px !important;
}

.slc-button-update-config:hover {
    background-color: #ffb500 !important;
}

.slc-quotations-row-data.slc-data-scegli .slc-button-quotation-selected {
    background-color: #0058A3;
}

@media screen and (min-width: 400px) {
    .slc-button-quotation {
        padding: 8px 16px !important;
    }
}

@media screen and (min-width: 600px) {

    .slc-button-quotation {
        min-width: 65%;
        max-width: 65%;
        font-size: 14px;
    }
    .slc-quotations-row-generic.slc-quotations-ecobonus-text .slc-quotations-cell {
        width: 100%;
    }
    
}

@media screen and (max-width: 600px) {
    .slc-data-label-info-content {
        min-width: initial;
        width: 200px
    }
}

@media screen and (min-width: 768px) {
    .slc-button-quotation {
        max-width: initial;
        height: 36px !important;
        line-height: 36px !important;
        padding: 0 16px !important;
    }
}

/*
@media screen and (min-width: 850px) {
    .slc-button-quotation {
        height: 36px !important;
        line-height: 36px !important;
        padding: 0 16px !important;
    
    }
}
*/

@media screen and (min-width: 992px) {
    .slc-quotations-row-data.slc-data-totale {}
    
    .slc-quotations-row-data.slc-data-totaleScontato {}

    .slc-quotations-row-data.slc-data-scegli {}
}

@media screen and (min-width: 1132px) {
    .slc-button-update-config {
        line-height: 10px !important;
    }
}