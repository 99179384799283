.slc-wizard .progtrckr {
    position: relative;
    top: -10px;
    margin: 0 0 0 12%; /* 6%: center the trckr (copes with trckr items alignment left (left, to hide the line(border) before the first elements)) */
    padding: 0;
    list-style-type: none;
}

.slc-wizard .progtrckr li {
    display: inline-block;
    width: 20%;
    text-align: center;
    /*cursor: pointer;*/
}

/* Hide unwanted steps textual names */
.slc-wizard .progtrckr li span,
.slc-wizard .progtrckr em {
    display: none;
}

/* Track line */
.slc-wizard .progtrckr li {
    color: black;
    border-bottom: 1px solid #8c8e8d;
}

/**
Track line colored version
todo, doing, done in different colors

.slc-wizard .progtrckr li.progtrckr-todo {
    color: black;
    border-bottom: 1px solid #8c8e8d;
}

.slc-wizard .progtrckr li.progtrckr-doing {
    color: black;
    border-bottom: 1px solid #2bbbad;
}

.slc-wizard .progtrckr li.progtrckr-done {
    color: black;
    border-bottom: 1px solid #f7bd01;
}
*/

/* Delete this for border before first and after last circle version */
.slc-wizard .progtrckr li:nth-of-type(5) {
    border-bottom: none;
}

/* Do not show the 10th step (it is implemented just to make the next button visible) */
.slc-wizard .progtrckr li:nth-of-type(6) {
    display: none;
}

.slc-wizard .progtrckr li:before {
    position: relative;
    float: left;
    transform: translateY(50%);
    /*transform: translate(-50%,50%); Use this for border before first and after last circle version */
    /*left: 50%; Use this for border before first and after last circle version */
}

/* Track circles */
.slc-wizard .progtrckr li:before {
    /*content: "\2713"; orig: 'V' symbol */
    width: 25px;
    line-height: 25px;
    font-size: 12px;
    color: white;
    border-radius: 12px;
}

/* Track circles different todo, doing, done colors */
.slc-wizard .progtrckr li.progtrckr-todo:before {
    background-color: #8c8e8d;
}

.slc-wizard .progtrckr li.progtrckr-doing:before {
    color: white;
    background-color: #f7bd01;
}

.slc-wizard .progtrckr li.progtrckr-done:before {
    background-color: #f7bd01;
}

/* Track circles texts */
.slc-wizard .progtrckr li:nth-of-type(1):before {
    content: "1";
}

.slc-wizard .progtrckr li:nth-of-type(2):before {
    content: "2";
}

.slc-wizard .progtrckr li:nth-of-type(3):before {
    content: "3";
}

.slc-wizard .progtrckr li:nth-of-type(4):before {
    content: "4";
}

.slc-wizard .progtrckr li:nth-of-type(5):before {
    content: "5";
}

.slc-wizard .progtrckr li:nth-of-type(6):before {
    content: "6";
}

.slc-wizard .progtrckr li:nth-of-type(7):before {
    content: "7";
}

.slc-wizard .progtrckr li:nth-of-type(8):before {
    content: "8";
}

.slc-wizard .progtrckr li:nth-of-type(9):before {
    content: "9";
}

/* Navigation */
.slc-wizard #prev-button {
    float: left;
}

.slc-wizard #next-button,
.slc-wizard #next-button-clone {
    float: right;
}

/* Some original styles eliminated */

/*
.slc-wizard .progtrckr li {
    display: inline-block;
    width: 80px;
    text-align: center;
    line-height: 4.5rem;
    cursor: pointer;
}
*/

/*
.slc-wizard .progtrckr li:before {
    position: relative;
    bottom: -4rem;
    float: left;
    left: 50%;
}
*/

/*
.slc-wizard .progtrckr li.progtrckr-todo:before {
    content: "\2713";
    color: white;
    background-color: #8c8e8d;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

.slc-wizard .progtrckr li.progtrckr-doing:before {
    content: "\2713";
    color: white;
    background-color: dodgerblue;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}

.slc-wizard .progtrckr li.progtrckr-done:before {
    content: "\2713";
    color: white;
    background-color: #f7bd01;
    width: 1.2em;
    line-height: 1.2em;
    border-radius: 1.2em;
}
*/