/* Pre IkeaWolmannInfo
.slc-page {
    display: flex;
    flex-direction: column;
    height: 100%;
}
*/

/* CSS Grid Implementation for IkeaWolmannInfo layout management */
.slc-page {
    display: grid;
    grid-template-rows: auto 3fr 1fr;
    grid-template-columns: minmax(0, 1fr);
    height: 100%;
}

.slc-page-header {
    margin-bottom: 30px !important;
    width: 100%;
}

.slc-page-header .col {
    padding: 0 !important;
}

.slc-page-body {
    font-size: .9em;
    width: 100%;
}

.slc-page-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: .9em;
    border-top: 1px solid #bbb;
}