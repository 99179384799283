.slc-quotations-form-response {
    position: absolute;
    background: rgba(255,255,255,1);
    width: 101%;
    height: 101%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 5;
}

.slc-quotations-form-response__icon {
    width: 100px;
    transform: translateY(-30px);
}

.slc-quotations-form-response__text {
    margin-bottom: 20px;
    font-size: 1.1em;
    text-align: center;
}

.slc-quotations-form-response__buttons button {
    margin-right: 3px;
}

.slc-quotations-form-response__buttons button:last-child {
    margin-right: 0;
}