.slc-step {
    display: flex;
    align-items: center;
    margin: 20px 0;
    min-height: 200px;
}

.slc-step-body {
    width: 100%;
}

.slc-circle {
    width: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 20px;
}

.slc-circle-fit-to-content {
    padding: 0 5px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: 20px;
}

.slc-option-label {
    text-transform: uppercase;
    color: white;
    background: #939393;
}

.selected .slc-option-label {
    background: #f7bd01;
}