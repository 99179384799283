* {
  box-sizing: border-box !important;
}

html, body {
  height: 100%;
  color: #5F595C;
}

.slc-app {
  height: 100%;
}

/**                                                                               Links
=======================================================================================
*/

.slc-text-link {
    font-weight: bold;
    color: #f7bd01;
}

/**                                                                                Grid
=======================================================================================
*/

@media screen and (min-width: 993px) {

  .slc-grid-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .slc-grid-3col .slc-grid-cell {
    width: 31%
  }
  
}

/**                                                                                 Box
=======================================================================================
*/

.slc-box-header {
  padding: 0 15px;
  min-height: 60px;
  display: flex;
  align-items: center;
  font-size: 1.1em;
  line-height: 1.1em;
  background-color: #f7bd01;
}

.slc-box-body {
  padding: 20px 5px;
}

.slc-box-item.slc-box-item--icon-txt {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.slc-box-item--icon-txt__txt {
  margin-left: 15px;
}

/**                                                                               Fonts
=======================================================================================
*/

.slc-font-app,
input,
select {
  font-family: 'Signika', sans-serif;
}

.slc-font-title {
  font-family: 'Signika';
  /*font-family: 'Novecentosanswide-Medium';*/
}

/**                                                                              Colors
=======================================================================================
*/

.slc-color-grey-light {
  color: #777;
}

.slc-bold {
    font-weight: bold;
}

/**                                                                                Form
=======================================================================================
*/ 

.slc-form-input-text {
  padding: 15px !important;
  font-size: .9rem !important;
  border: 1px solid #cbcbcb !important;
}

.slc-form-input-text:focus {
  border: 1px solid #d7defa !important;
  box-shadow: none !important;
}

/**
Overwrites materializecss rules
*/
.slc-checkbox {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: all !important;
}

.slc-select {
    display: block;
    pointer-events: all !important;
    width: auto;
    margin: 0 auto;
    height: auto;
    padding: 3px;
    border: 1px solid #bbb;
}

::placeholder {
  font-size: .9rem;
  color: #888;
}

/**                                                          slc-form-input-text-widget
=======================================================================================
  HTML structure
  --------------  
    slc-form-input-text-widget
      slc-form-input-text-widget__label
      slc-form-input-text-widget__input-container
        slc-form-input-text-widget__input
        slc-form-input-text-widget__input-measure-unit
        slc-form-input-text-widget__input-infos
*/

.slc-form-input-text-widget {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 15px;
}

.slc-form-input-text-widget--with-infos {
  margin-bottom: 30px;
}

.slc-form-input-text-widget:last-child {
  margin-bottom: 0;
}

.slc-form-input-text-widget__label {
  margin: 0 10px 0 0;
  font-weight: normal;
}

.slc-form-input-text-widget__input-container {
  position: relative;
  width: 100% !important;
  display: flex;
  align-items: center;
}

.slc-form-input-text-widget__input-container--with-error-msg {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.slc-form-input-text-widget__input {
  margin: 0 !important;
  padding: 5px 15px !important;
  height: 25px !important;
  font-size: .9rem !important;
  text-align: right;
  border: 1px solid #cbcbcb !important;
}

.slc-form-input-text-widget__input:focus {
  border: 1px solid #d7defa !important;
  box-shadow: none !important;
}

.slc-form-input-text-widget__input-measure-unit {
  position: absolute;
  left: 5px;
  font-size: .9em;
  opacity: .6;
}

.slc-form-input-text-widget__input-infos
{
  position: absolute;
  top: 100%;
  font-size: .9em;
}

.slc-form-item-validation-error {
  border: 1px solid red !important;
}

.slc-form-item-validation-error:focus {
  border: 1px solid red !important;
}

.slc-form-submitBtn-disabled {
  opacity: .5;
}

/*
.slc-form-validation-error {
  display: flex;
  align-items: center;
}
*/

.slc-form-validation-error {
  display: flex;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 5px;
  border-radius: 5px;
}

.slc-form-validation-error:hover {
  cursor: pointer;
}

.slc-form-validation-error__icon {
  color: red;
  line-height: 20.25px;
}

.slc-form-validation-error__message {
  margin-left: 5px;
}

@media screen and (min-width: 420px) {

  .slc-form-input-text-widget {
    flex-direction: row;
    align-items: center;
  }

  .slc-form-input-text-widget__label {
    max-width: 50%;
  }

  .slc-form-input-text-widget__input-container {
    width: 30% !important;
  }

  .slc-form-input-text-widget__input {
    padding: 15px !important;
    height: auto !important;
  }

}

/**                                                               slc-form-input-select
=======================================================================================
  HTML structure
  --------------  
    slc-form-input-select-widget
      slc-form-input-select-widget__label
      slc-form-input-select-widget__input-container
        slc-form-input-select-widget__input
*/

.slc-form-input-select-widget {
  margin-bottom: 15px;
}

.slc-form-input-select-widget__input {
  display: block;
  height: 30px;
  appearance: button;
  border: 1px solid #cbcbcb;
}

.slc-form-input-select-widget__input:focus {
  border: 1px solid #d7defa;
  outline: 0;
}

/**                                                             slc-form-input-checkbox
=======================================================================================
  HTML structure
  --------------  
    slc-form-input-checkbox-widget
      slc-form-input-checkbox-widget__input-container
        slc-form-input-checkbox-widget__input
*/

.slc-form-input-checkbox-widget {
  margin-bottom: 15px;
}

.slc-form-input-checkbox-widget:hover {
  cursor: pointer;
}

/**                                                                slc-form-input-radio
=======================================================================================
  HTML structure
  -------------- 
    slc-form-input-radio-widget
      slc-form-input-radio-widget__label
      slc-form-input-radio-widget__radio-container
        slc-form-input-radio-widget__input
        slc-form-input-radio-widget__input-label
*/

.slc-form-input-radio-widget {}

/* Override materializecss */
[type="radio"].slc-form-input-radio-widget__input {
  position: relative;
  opacity: 1;
  pointer-events: all;
}

.slc-form-input-radio-widget__input-label {
  margin-left: 5px;
}

.slc-form-input-radio-widget--horizontal-choices .slc-form-input-radio-widget__input-container,
.slc-form-input-radio-widget--horizontal-choices .slc-form-input-radio-widget__input-label {
  display: inline-block
}

.slc-form-input-radio-widget--horizontal-choices
  .slc-form-input-radio-widget__input-container {
  margin-right: 12px
}

/**                                                        Materializecss customization
=======================================================================================
*/

button {
  box-shadow: none !important;
}

.btn, .btn-large, .btn-small {
  color: white;
  background-color: #f7bd01;
}

.btn:hover, .btn-large:hover, .btn-small:hover {
  background-color: #ffc403;
}

.btn:focus, .btn-large:focus, .btn-small:focus {
  background-color: #ffc403;
}